<template>
  <div id="app">
    <!-- Header -->
    <header>
      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div class="container-fluid">
          <div class="social-media-module">
            <ul class="social-list">
              <li class="social-item">
                <a href="https://twitter.com/famousapeMC">
                  <img src="assets/img/twitter.png" alt="" />
                </a>
              </li>
              <li class="social-item">
                <a href="https://instagram.com/famousapeMC">
                  <img src="assets/img/instagram.png" alt="" />
                </a>
              </li>
              <li class="social-item">
                <a href="https://discord.gg/famousapemovieclub">
                  <img src="assets/img/discord.png" alt="" />
                </a>
              </li>
            </ul>
          </div>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggl-icon">
              <input type="checkbox" id="menu_checkbox" />
              <label for="menu_checkbox">
                <div></div>
                <div></div>
                <div></div>
              </label>
            </span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  aria-current="page"
                  href="#aboutSection"
                  >About Us</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  aria-current="page"
                  href="#roadMapSection"
                  >Roadmap</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  aria-current="page"
                  href="#faqSection"
                  >Faq</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  aria-current="page"
                  href="#futuresSection"
                  >Traits</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link active"
                  aria-current="page"
                  href="#videoSection"
                  >Metaverse</a
                >
              </li>
            </ul>
          </div>
          <div class="action">
            <div class="custom-button-wrapper">
              <button @click="bagla()" class="button">
                {{ connectionwallet() }}
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
    <!-- Header /-->

    <!-- Top section -->
    <section id="topSection">
      <div id="mintModule">
        <div class="head">
          <h1>
            Pre-Sale: LIVEEE !!!
          </h1>
          <h1>
            Public Sale: 24.12.2021 23:00 UTC
          </h1>
        </div>
        <div class="mint-button-increment-decrement">
          <div class="increment-decrement">
            <div class="decrement-wrapper incdec-wrapper">
              <a
                @click="incrementValueks()"
                id="buttonDecrement"
                class="mint-incdec-button-style"
              >
                <img src="assets/img/minus.png" alt="" />
              </a>
            </div>
            <input
              type="text"
              @input="mintcount = $event.target.value"
              :value="mintcount"
              id="piece"
              name="piece"
            />
            <div class="increment-wrapper incdec-wrapper">
              <a
                @click="incrementValue()"
                id="buttonIncrement"
                class="mint-incdec-button-style"
              >
                <img src="assets/img/plus.png" alt="" />
              </a>
            </div>
          </div>
          <div class="mint-button-wrapper">
            <a @click="buyFameApePublic()" class="mint-button">MINT</a>
          </div>
        </div>
      </div>

      <div class="animated-mouse-down">
        <a href="#aboutSection">
          <svg
            width="30px"
            height="100%"
            viewBox="0 0 247 390"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;"
          >
            <path
              id="wheel"
              d="M123.359,79.775l0,72.843"
              style="fill:none;stroke:#ffffff;stroke-width:30px;"
            />
            <path
              id="mouse"
              d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"
              style="fill:none;stroke:#ffffff;stroke-width:30px;"
            />
          </svg>
        </a>
      </div>
    </section>
    <!-- Top section /-->

    <!-- About Us section -->
    <section id="aboutSection">
      <div class="container">
        <div class="inner-row">
          <div class="left">
            <div class="head">
              <h2>
                <span class="left">
                  WELCOME TO
                </span>
                <span class="right">
                  FAMOUS APE MOVIE CLUB
                </span>
              </h2>
            </div>
            <div class="description">
              <p>
                Famous Ape Movie Club is full of apes that are performers, have
                jobs and are leading fulfilling lives! This is a long-term
                Metaverse project aiming to establish an expansive ecosystem at
                every turn with every single one of these valuable apes.
                Anything is possible with the correct NFT. You want to go to
                space? The astronaut ape will help you break through the
                atmosphere. Or perhaps a cowboy ape, infamous for his quick
                draw! No matter which ape they are, however, they are all going
                to the moon! Planet of the Famous Apes!
              </p>
            </div>
          </div>
          <div class="right image-right">
            <img src="assets/img/famousapegif1.png" alt="gif" />
          </div>
        </div>
      </div>
    </section>
    <!-- About Us section /-->

    <!-- Roadmap section -->
    <section id="roadMapSection">
      <div class="container">
        <div class="section-top">
          <div class="head">
            <h2>
              OUR ROADMAP
            </h2>
          </div>
        </div>
        <div class="section-bottom">
          <ul class="road-list">
            <li class="road-item">
              <span class="percent">
                15%
              </span>
              <span class="road-map-description">
                <p>
                  We'll airdrop Famous Ape Movie Club to five lucky Famous Ape
                  owners.
                </p>
              </span>
            </li>
            <li class="road-item">
              <span class="percent">
                25%
              </span>
              <span class="road-map-description">
                <p>
                  An airdrop of 1 ETH in total will be distributed.
                </p>
              </span>
            </li>
            <li class="road-item">
              <span class="percent">
                50%
              </span>
              <span class="road-map-description">
                <p></p>
                We will shoot an introductory film and models will help create a
                catalogue for the Famous Apes.
              </span>
            </li>
            <li class="road-item">
              <span class="percent">
                75%
              </span>
              <span class="road-map-description">
                <p>
                  A special song for the Famous Ape Movie Club will be composed,
                  the song will be remixed by a DJ and then released.
                  Furthermore, 2 ETH and 10 Famous Apes will be airdropped.
                </p>
              </span>
            </li>
            <li class="road-item">
              <span class="percent">
                100%
              </span>
              <span class="road-map-description">
                <p>
                  We'll airdrop 5 ETH and Famous Apes will turn to 3D!
                </p>
              </span>
            </li>
            <li class="road-item">
              <span class="percent">
                V2
              </span>
              <span class="road-map-description">
                <p>
                  Starting Metaverse studies and initial designs will be shared
                  with the members.
                </p>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- Roadmap section /-->

    <!-- FAQ section -->
    <section id="faqSection">
      <div class="container">
        <div class="head">
          <h2>
            FAQ
          </h2>
        </div>

        <div class="default-accordion">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <span>
                    How many Famous Apes are there?
                  </span>
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <h3>
                    5,555 Famous Apes will be available for minting. Each Ape
                    will be unique with some partially unique features. After
                    minting, you will be able to carry them in your wallets and
                    be able to sell on OpenSea. Do remember that owning a Famous
                    Ape is a matter of luck, too!
                  </h3>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <span>
                    Is there a Famous Ape Movie Club Whitelist?
                  </span>
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <h3>
                    Yes. Some of the members who support us and complete various
                    tasks will earn the right for WL. Please visit our discord
                    for more detailed information.
                  </h3>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <span>
                    When is the launch date?
                  </span>
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <h3>
                    Pre-sale: LIVEEE !!! <br />
                    Public Sale: 24.12.2021 (December 24, 2021) 23:00 UTC
                  </h3>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <span>
                    What about Mint Price?
                  </span>
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <h3>
                    We are offering a 50 percent discount to our whitelist
                    members and start their minting process at 0.08 ETH. Public
                    sale minting price will be 0.12 ETH to raise the floor price
                    and protect the investments of our whitelist members.
                  </h3>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <span>
                    Do you have a limit for minting?
                  </span>
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <h3>Mint limit per member is 10.</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingSix">
              <button
                class="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <span>
                  Do you do giveaways?
                </span>
              </button>
            </h2>
            <div
              id="collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div class="accordion-body">
                <h3>
                  We hold giveaways in our Discord server and social media
                  accounts. Please follow through our links to benefit from
                  these.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- FAQ section /-->

    <!-- Futures section -->
    <section id="futuresSection">
      <div class="container">
        <div class="left">
          <div class="head">
            <h3>
              RARITY
            </h3>
          </div>
          <div class="left-bottom">
            <ul class="futures-list">
              <li class="futures-item">
                <span class="inner-left">
                  5,555
                </span>
                <span class="inner-right">
                  Total Apes
                </span>
              </li>
              <li class="futures-item">
                <span class="inner-left">
                  34
                </span>
                <span class="inner-right">
                  Skins
                </span>
              </li>
              <li class="futures-item">
                <span class="inner-left">
                  30
                </span>
                <span class="inner-right">
                  Clothes
                </span>
              </li>
              <li class="futures-item">
                <span class="inner-left">
                  29
                </span>
                <span class="inner-right">
                  Headwears
                </span>
              </li>
              <li class="futures-item">
                <span class="inner-left">
                  30
                </span>
                <span class="inner-right">
                  Mouths
                </span>
              </li>
              <li class="futures-item">
                <span class="inner-left">
                  34
                </span>
                <span class="inner-right">
                  Eyes
                </span>
              </li>
              <li class="futures-item">
                <span class="inner-left">
                  10
                </span>
                <span class="inner-right">
                  Earrings
                </span>
              </li>
              <li class="futures-item">
                <span class="inner-left">
                  33
                </span>
                <span class="inner-right">
                  Backgrounds
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div class="right">
          <img src="assets/img/rarity-bg.png" alt="" />
        </div>
      </div>
    </section>
    <!-- Futures section /-->

    <!-- Video section -->
    <section id="videoSection">
      <div class="container">
        <div class="head">
          <h2>
            METAVERSE
          </h2>
        </div>
        <div class="videos">
          <figure>
            <img src="assets/img/1.gif" alt="" />
            <p>
              Your 2D Famous Ape NFT becomes 3D! Show off your unique ape to
              everyone in the metaverse!
            </p>
          </figure>
          <figure>
            <img src="assets/img/2.gif" alt="" />
            <p>
              By buying and holding our NFT you will gain exclusive access to
              Famous Ape apparel which you can wear in the metaverse!
            </p>
          </figure>
        </div>
      </div>
    </section>
    <!-- Video section /-->

    <!-- Footer section -->
    <footer id="footerSection">
      <div class="container">
        <div class="footer-column">
          <div class="column-head">
            About
          </div>
          <ul class="column-list">
            <li class="column-item">
              <a href="#">
                How to Buy
              </a>
            </li>
            <li class="column-item">
              <a href="#">
                Futures
              </a>
            </li>
            <li class="column-item">
              <a href="#">
                Opensea
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-column">
          <div class="column-head">
            Information
          </div>
          <ul class="column-list">
            <li class="column-item">
              <a href="#">
                Terms of Service
              </a>
            </li>
            <li class="column-item">
              <a href="#">
                Privacy Policy
              </a>
            </li>
            <li class="column-item">
              <a href="#">
                Roadmap V2
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-column">
          <div class="column-head">
            Rarity
          </div>
          <ul class="column-list">
            <li class="column-item">
              <a href="#">
                Rarity.tools
              </a>
            </li>
            <li class="column-item">
              <a href="#">
                Rarity Sniffer
              </a>
            </li>
            <li class="column-item">
              <a href="#">
                Rarity Sniper
              </a>
            </li>
          </ul>
        </div>
        <div class="footer-column social-media">
          <div class="column-head">
            Connect
          </div>
          <ul class="column-list">
            <li class="social-item">
              <a href="https://twitter.com/famousapeMC">
                <img src="assets/img/twitter.png" alt="" />
              </a>
            </li>
            <li class="social-item">
              <a href="https://instagram.com/famousapeMC">
                <img src="assets/img/instagram.png" alt="" />
              </a>
            </li>
            <li class="social-item">
              <a href="https://discord.gg/famousapemovieclub">
                <img src="assets/img/discord.png" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="container-fluid">
        <div class="footer-images">
          <div class="image">
            <img src="assets/img/f1.png" alt="" />
          </div>
          <div class="image">
            <img src="assets/img/f2.png" alt="" />
          </div>
          <div class="image">
            <img src="assets/img/f3.png" alt="" />
          </div>
          <div class="image">
            <img src="assets/img/f4.png" alt="" />
          </div>
          <div class="image hidden-xs">
            <img src="assets/img/f5.png" alt="" />
          </div>
          <div class="image hidden-xs">
            <img src="assets/img/f6.png" alt="" />
          </div>
          <div class="image hidden-xs">
            <img src="assets/img/f7.png" alt="" />
          </div>
          <div class="image hidden-xs">
            <img src="assets/img/f8.png" alt="" />
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer section /-->
  </div>
</template>

<script>
import Web33 from "web3";
import getWeb3 from "../contracts/web3";
import WalletProv from "../walletconnect";
//import metamask from "../contracts/metamask";
import contractAbi from "../contracts/abi";
import EthCrypto from "../contracts/ethcryp";
//import web3 from '../contracts/walletconnect';
const contractAddress = "0x2a171176a9ad54b86a4a6638B1D5126fB7F83ae3";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      signsay: 0,
      meta: null,
      cnwallet: null,
      newweb3: null,
      wallet: false,
      test11: null,
      mintcount: 1.0,
      web3: null,
      account: null,
      contractInstance: null,
      gene: null,
      matron: null,
      sire: null,
      vipers: [],
      isLoading: false,
      keccak: null,
      sign: null,
      signs: {},
      wallets2: ["0x7150DdcABE48b7dC983B269d0EC40bB5c54df877"],
      wallets: [
        "0x51dBB33E4638A30C108cC5a4421b7617B86768F2",
        "0xaa5dab3f0e522bdf1bc71b7e4f4571c459f32f7f",
        "0x33E194ab6eA7d9923AC6986629F708714bB6e6bB",
        "0x639c93E5511462aCd49628b4EDC9BEd71b0c8361",
      ],
    };
  },
  mounted() {
    getWeb3().then(res => {
      console.log("r1");
      console.log(res);
      console.log("r2");
      this.newweb3 = res;
      this.web3 = new Web33(window.ethereum);
      if (res != null) {
        if (res.selectedAddress == null) {
          this.wallet = false;
        } else {
          this.web3.eth.getAccounts().then(accounts => {
            console.log(accounts);
            [this.account] = accounts;
            this.wallet = true;
            this.meta = true;
          });
          this.contractInstance = new this.web3.eth.Contract(
            contractAbi,
            contractAddress
          );
          console.log(this.account);
          this.wallet = true;
        }
      }
    });
    // getWeb3().then(res => {
    //   this.web3 = res;
    //   this.contractInstance = new this.web3.eth.Contract(
    //     contractAbi,
    //     contractAddress
    //   );
    //   this.web3.eth
    //     .getAccounts()
    //     .then(async accounts => {
    //       [this.account] = accounts;
    //       try {
    //         console.log(this.account);
    //         const keccak1 =
    //           "0x" +
    //           this.web3.utils
    //             .soliditySha3({
    //               //  type: "uint256",
    //               // value: "0x8A10226F49F10d8bC7772C7FAB65D46fb315A7B1",
    //               type: "address",
    //               value: "0x8A10226F49F10d8bC7772C7FAB65D46fb315A7B1",
    //               //["address"],
    //               //["0x8A10226F49F10d8bC7772C7FAB65D46fb315A7B1"]
    //             })
    //             .toString("hex");
    //         this.keccak = keccak1;
    //         console.log("mesaj1");
    //         console.log(this.keccak);
    //         // await this.web3.eth
    //         //   .sign("testsign", this.account)
    //         //   .then(sign1 => {
    //         //     console.log("testsing2");
    //         //     console.log(sign1);
    //         //     this.sign = sign1;
    //         //   })
    //         //   .catch(err => {
    //         //     console.log(err);
    //         //   });
    //       } catch (error) {
    //         console.log(error);
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err, "err!!");
    //     });
    // });
  },
  components: {
    // HelloWorld,
  },
  methods: {
    connectionwallet() {
      if (this.wallet == false) {
        return "Connection Wallet";
      } else {
        if (this.meta == false) {
          return "Connected";
        } else {
          return "Connected";
        }
      }
    },
    thanks() {
      alert("Thank you so much! SOLD OUT !!");
    },
    mintotalethpublic() {
      const eth = (this.mintcount * 0.12).toFixed(2);
      var streth = eth.toString();
      return streth;
    },
    mintotaleth() {
      const eth = (this.mintcount * 0.08).toFixed(2);
      var streth = eth.toString();
      console.log(streth);
      // if (streth.length > 4) {
      //   console.log(streth);
      //   const newstr = streth.slice(0, 4);
      //   console.log(newstr);
      //   streth = newstr;
      // }
      return streth;
      // if (streth == 0.32) {
      //   return 0.33;
      // } else if (streth == 0.44) {
      //   return 0.45;
      // } else {
      //   return streth;
      // }
      //eth.toString();
    },
    incrementValue() {
      if (this.mintcount < 10) {
        this.mintcount += 1;
        console.log(this.mintcount);
      }
    },
    incrementValueks() {
      if (this.mintcount > 1) {
        this.mintcount--;
      }
    },
    kontrol() {
      if (this.mintcount > 10) {
        alert("Max 10");
      }
    },
    async bagla() {
      if (this.newweb3 == window.ethereum && this.newweb3 != null) {
        this.web3 = new Web33(window.ethereum);
        if (this.wallet == false) {
          this.newweb3.enable().then(() => {
            this.web3.eth.getAccounts().then(accounts => {
              console.log(accounts);
              [this.account] = accounts;
              this.wallet = true;
              this.meta = true;
            });
          });
          this.contractInstance = new this.web3.eth.Contract(
            contractAbi,
            contractAddress
          );
        }
      } else {
        //WALLETCONNECT
        try {
          this.cnwallet = WalletProv();
          await this.cnwallet.enable();
          this.cnwallet.on("disconnect", async () => {
            await this.cnwallet.disconnect();
            await this.cnwallet.close();
            this.wallet = false;
          });
          this.web3 = new Web33(this.cnwallet);
          const accounts = await this.web3.eth.getAccounts();
          this.contractInstance = new this.web3.eth.Contract(
            contractAbi,
            contractAddress
          );
          [this.account] = accounts;
          console.log("ac1");
          console.log(this.account);
          this.wallet = true;
          this.meta = false;
        } catch (error) {
          console.log(error);
        }
      }
    },
    async buyFameApe() {
      if (this.mintcount > 10) {
        alert("Max 10");
      } else {
        await axios
          .get(
            "https://sign.famousapemovieclub.io/api.famousapemovieclub.io/sign/" +
              this.account,
            { headers: { "X-Requested-With": "XMLHttpRequest" } }
          )
          .then(result => {
            console.log(result);
            if (
              result.data == undefined ||
              result.data == null ||
              result.data == "" ||
              result.data == " "
            ) {
              alert("Sorry!! You are not in whitelist");
            } else {
              console.log(result.data.sign);
              console.log(this.mintcount);
              console.log(this.account);
              console.log(this.mintotaleth());
              this.contractInstance.methods
                .presaleMint(
                  result.data.keccak,
                  result.data.sign,
                  this.mintcount
                )
                .send({
                  from: this.account,
                  value: this.web3.utils.toWei(this.mintotaleth(), "ether"),
                })
                .then(receipt => {
                  console.log("recipt" + receipt);
                  this.isLoading = false;
                })
                .catch(err => {
                  console.log(err, "err");
                  this.isLoading = false;
                });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },

    async buyFameApePublic() {
      if (this.mintcount > 10) {
        alert("Max 10");
      } else {
        this.contractInstance.methods
          .publicSaleMint(this.mintcount)
          .send({
            from: this.account,
            value: this.web3.utils.toWei(this.mintotalethpublic(), "ether"),
          })
          .then(receipt => {
            console.log("recipt" + receipt);
            this.isLoading = false;
          })
          .catch(err => {
            console.log(err, "err");
            this.isLoading = false;
          });
      }
    },
    async signgetir() {
      this.signgetir()
        .then(result => {
          console.log("res2");
          console.log(result);
          console.log("res1");
        })
        .catch(err => {
          console.log(err);
        });
    },
    async signfunc2() {
      try {
        //.mintBunny(this.account, this.mintcount)

        console.log("test");
        //const signerIdentity = EthCrypto.createIdentity();
        if (this.wallets.length == 0) {
          try {
            const data = JSON.stringify(this.signs);
            console.log("dadad");
            console.log(data);
            await axios.get("http://192.168.1.2:3000/test/" + data);
            alert("bitti");
          } catch (error) {
            console.log(error);
          }
        }
        const message = EthCrypto.hash.keccak256([
          {
            type: "uint256",
            value: this.wallets[0],
          },
        ]);
        this.web3.eth
          .sign(message, "0x454359b0ba79eEC5331dE58077AF2B2b2576639F")
          .then(result => {
            console.log("ok5");
            console.log(result);
            this.signsay += 1;
            console.log(this.signsay);
            this.signs[this.wallets[0]] = { keccak: message, sign: result };
            this.wallets.shift();
          })
          .catch(err => {
            console.log(err);
          });
        console.log("test2");
        // const prky = signerIdentity.privateKey;
        // console.log(prky);
        // const signature = EthCrypto.sign(signerIdentity.privateKey, message);
        // console.log(`message: ${message}`);
        // console.log(`signature: ${signature}`);
        // console.log(`signer public key: ${signerIdentity.address}`);
      } catch (error) {
        console.log(error);
      }
    },
    buyBunny() {
      if (this.mintcount < 21) {
        this.isLoading = true;
        this.contractInstance.methods
          .mintBunny(this.account, this.mintcount)
          .send({
            from: this.account,
            value: this.web3.utils.toWei(this.mintotaleth(), "ether"),
          })
          .then(receipt => {
            console.log("recipt" + receipt);
            this.isLoading = false;
          })
          .catch(err => {
            console.log(err, "err");
            this.isLoading = false;
          });
      } else {
        alert("Max 20");
      }
    },
  },
};
</script>

<style>
.quantity {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.quantity__minus,
.quantity__plus {
  display: block;
  width: 70px;
  height: 70px;
  margin: 0;
  background: #dc3545;
  text-decoration: none;
  text-align: center;
  line-height: 70px;
}
.quantity__minus:hover,
.quantity__plus:hover {
  background: #dc3545;
  color: #dc3545;
}
.quantity__minus {
  border-radius: 5px 0 0 5px;
}
.quantity__plus {
  border-radius: 0 5px 5px 0;
}
.quantity__input {
  width: 110px;
  height: 70px;
  margin: 0;
  padding: 0;
  text-align: center;
  border-top: 2px solid #dc3545;
  border-bottom: 2px solid #dc3545;
  border-left: 1px solid #dc3545;
  border-right: 2px solid #dc3545;
  background: #000;
  color: #fff;
}
.quantity__minus:link,
.quantity__plus:link {
  color: #8184a1;
}
.quantity__minus:visited,
.quantity__plus:visited {
  color: #000;
}
</style>
