import WalletConnectProvider from "@walletconnect/web3-provider";
//  Create WalletConnect Provider

const WalletProv = () => {
  const provider = new WalletConnectProvider({
    infuraId: "19aeeccb1bc542b894a80eeffc995007",
  });
  return provider;
  // //  Enable session (triggers QR Code modal)
  // await provider.enable();
  // //await provider.disconnect();
  // provider.on("accountsChanged", accounts => {
  //   console.log(accounts);
  // });

  // // Subscribe to chainId change
  // provider.on("chainChanged", chainId => {
  //   console.log(chainId);
  // });

  // // Subscribe to session disconnection
  // provider.on("disconnect", async () => {
  //   //  console.log(code, reason);
  //   await provider.disconnect();
  // });
  // const web33 = new Web3(provider);
  // return web33;
};
//  Create Web3 instance

export default WalletProv;
